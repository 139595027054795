const mobWorkContainer = document.querySelectorAll(
    ".section-two__workcontainer"
  );
  
  const mobWorkPackageBox = document.querySelectorAll(
    ".section-two__packaging-box"
  );
  
  const options = {
    root: null,
    threshold: 0.1,
  };
  
  function callback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const arr = Array.from(entry.target.children[1].children)
        entry.target.classList.add("show");
        arr.forEach((ar) => {
          ar.classList.add('addskew')
        })      
        observer.unobserve(entry.target);
      } else {
        entry.target.classList.remove("show");
      }
    });
  }
  
  const observer = new IntersectionObserver(callback, options);
  
  mobWorkContainer.forEach((container) => {
    observer.observe(container);
  });
  
  /** ABOUT-ME-OBSERVER */
  
  const aboutPara = document.querySelector('.section-three__aboutme-paratwo'); 
  const aboutSpan = document.querySelector('.section-three__aboutme-parataspan'); 
  
  const aboutOption = {
    root: null, 
    threshold: 0.5
  }
  
  function aboutCall(entries) {
    entries.forEach((entry) => {
      if(entry.isIntersecting) {
        aboutSpan.style.color = '#f93'
        aboutSpan.style.transition='color 2s ease';
      } else {
        aboutSpan.style.color = '#ffffff'
      } 
    })
  }
  
  
  const aboutObserver = new IntersectionObserver(aboutCall, aboutOption)
  
  aboutObserver.observe(aboutPara)
  